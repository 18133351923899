* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@font-face {
    font-family: silka;
    src: url(/public/fonts/silka-regular-webfont.woff);
}

@font-face {
    font-family: silka;
    font-weight: 700;
    src: url("/public/fonts/silka-semibold.otf") format("opentype");
}

@font-face {
    font-family: silka;
    font-weight: 900;
    src: url("/public/fonts/silka-bold.otf") format("opentype");
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
    /* color: #121535; */
    color: #1f3a45;
    font-family: 'silka', sans-serif;
    letter-spacing: -0.2px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
    /* color: #1b1f4b; */
    color: #1f3a45;
    font-weight: 900;
}

a {
    text-decoration: underline;
    color: #1c3502;
}

a:hover {
    text-decoration: underline;
}

.para_break {
    display: block;
    height: 8px;
}

.ant-btn-primary {
    box-shadow: none;
}

.ant-timeline.ant-timeline-label {
    .ant-timeline-item.ant-timeline-item-left {
        .ant-timeline-item-label {
            width: calc(30% - 12px);
        }

        .ant-timeline-item-tail {
            inset-inline-start: 30%;
        }

        .ant-timeline-item-head {
            inset-inline-start: 30%;
        }

        .ant-timeline-item-content {
            inset-inline-start: calc(30% - 4px);
            width: calc(70% - 12px);
        }
    }
}

.ant-tag,
.ant-alert {
    border: none
}

.ant-tag.ant-tag-green {
    background: #e6ffcc;
}

.ant-modal-title {
    padding-right: 20px;
}

.recharts-wrapper {
    width: 100% !important;
}
.recharts-surface{
    overflow: visible;
}