.wrapper {
    display: flex;
}

.left {
    flex-grow: 1;
}

.itemName {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.circle {
    width: 14px;
    height: 14px;
    background-color: #4edd129c;
    border-radius: 50%;
    margin-right: 6px;
    line-height: 18px;

    &.LIVE {
        background-color: #4edd129c;
    }

    &.CLOSED {
        background-color: #d4380d;
    }
}

.anonymous {
    font-weight: bold;
    margin-left: 8px;
}

.frequency {
    font-weight: bold;
    margin-left: 8px;
}

.itemDates {
    font-size: 14px;
    margin-top: 4px;
    margin-left: 2px;
    color: grey;
    display: flex;
}

.deadline {
    margin-left: 10px;
}

.itemStatus {
    display: flex;
    margin-bottom: 12px;
}

.itemStatusInner {
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: bold;
}