.threadWrapper {
    margin-top: 8px;
}

.threadUsernameWrapper {
    display: flex;

    .threadUsername {
        font-size: 14px;
        color: #65676b;
        margin-left: 3px;
    }
}

.threadElement {
    margin-bottom: 12px;

    .threadTag {
        flex-grow: 1;
        margin-right: 8px;
    }

    .threadContainer {
        width: fit-content;
        max-width: calc(100% - 84px);
    }

    .threadInner {
        padding: 14px;
        border-radius: 10px;
    }

    &.threadQuestion {
        display: flex;
        justify-content: flex-end;

        .threadInner {
            background-color: #f5fbff;
        }
    }

    &.threadAnswer {


        .threadInner {
            background-color: #f3ffe6;
        }
    }

    .threadElementDate {
        font-size: 12px;
        color: grey;
        display: flex;
        align-items: center;
    }
}

.linkAskClarification {
    display: flex;
    font-weight: bold;
    cursor: pointer;
    color: #c4c4c4;
    margin-top: 6px;
    margin-bottom: 16px;
    font-size: 14px;

    &:hover {
        color: #6baed7;
    }
}

.threadInnerTop {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.clarificationCTAs {
    display: flex;
    margin-top: 12px;

    .clarificationHint {
        flex-grow: 1;
        font-size: 12px;
        color: grey;
        margin-right: 8px;
    }
}

@media (max-width: 550px) {
    .clarificationCTAs {
        flex-wrap: wrap;

        .clarificationHint {
            margin-bottom: 8px;
        }
    }
}