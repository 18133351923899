.userWrapper {
    font-weight: bold;
}

.userElement {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.username {
    font-weight: bold;
}

.comment {
    font-style: italic;
}

.actions {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.answerContent {
    margin-bottom: 8px;
}

.actionsRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.avatarWrapper {
    position: relative;
}

.avatarStatus {
    position: absolute;
    bottom: -6px;
    right: 0px;
    z-index: 12;


    .statusIcon {
        font-size: 18px;
        background-color: white;
        border-radius: 50%;
        width: 18px;
        height: 18px;
    }
}

.usernameWrapper {
    text-align: left;
    font-weight: normal;
    margin-left: 12px;
}

.date {
    color: grey;
}

.answer {
    .answerInner {
        padding-bottom: 6px;
    }

}