.loading {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
}

.text {
    margin-top: 24px;
    font-size: 24px;
    color: black;
}