.title {
    margin-bottom: 4px;
}

.userElement {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.username {
    font-weight: bold;
}

.list {
    margin-top: 12px;
    list-style: none;
}

.nameDateWrapper {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.submitted {
    color: grey;
}

.avatarWrapper {
    position: relative;
}

.avatarStatus {
    position: absolute;
    bottom: -6px;
    right: 0px;
    z-index: 12;


    .statusIcon {
        font-size: 18px;
        background-color: white;
        border-radius: 50%;
        width: 18px;
        height: 18px;
    }
}

.iconTitle {
    svg {
        height: 18px;
    }
}