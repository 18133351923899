.itemHeader {
    font-size: 14px;
}

.participationContent {
    min-width: 130px;
}

.itemParticipationInner {
    display: flex;
    align-items: flex-end;
}

.percentageParticipation {
    font-size: 34px;
}

.numberPeople {
    margin-bottom: 6px;
    margin-left: 12px;
}

.listColor {
    list-style: none;

    li {
        margin-bottom: 6px;
    }
}

.wrapper {
    display: flex;
}

.charts {}

.incompleteResponsesCount {
    margin-top: 8px;
}