.participationWrapper {
    margin-top: 24px;
    padding: 24px;
    background-color: #00000005;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
}

.bread {
    margin-bottom: 34px;
}

.participationWrapper {
    display: flex;
}

.viewUsersList {
    margin-left: 8px;
    cursor: pointer;
}

.avatar {
    margin-left: -6px;
    box-shadow: 0px 0px 6px #00000038;
}

.participationRight {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

.participationLeft {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.lastResponse {
    font-size: 14px;
    color: grey;
    margin-top: 8px;
}


.userList {
    display: flex;
    justify-content: flex-end;
}

.title {
    margin-bottom: 12px;
}

.subtitle {
    margin-bottom: 12px;
    color: grey;
}

.responseWrapper {
    margin-top: 44px;
}

.questionNumberInTitle {
    color: grey
}

.questionHeader {
    display: flex;
    align-items: center;

    .questionHeaderLeft {
        flex-grow: 1
    }

    .questionHeaderRight {
        display: flex;
        align-items: center;
    }
}

.questionWrapper {
    margin-bottom: 24px;
}

.vignettes {
    display: flex;
}

.vignetteElement {
    padding: 34px;
    text-align: center;
    flex-basis: 25%;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    position: relative
}

.vignetteElementClickable {
    cursor: pointer;

    &:hover,
    &.selected {
        background: #c8dbff;
    }
}

.loaderInVignette {
    position: absolute;
    top: 0;
    right: 10px
}

.number {
    font-size: 36px;
    font-weight: bold;
    min-height: 80px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vignetteGraph {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    min-height: 110px;
}

.contentOpenEnded {
    font-style: italic;
    color: grey;
    margin-top: 12px;
}

.graphWrapper {
    background-color: #c8dbff;
    min-height: 120px;
    width: 100%;
    padding: 24px;
}

.graphTitle {
    font-weight: bold;
    flex-grow: 1;
}

.graphHeader {
    display: flex;
}

.over {
    font-size: 14px;
    font-weight: normal;
}

.statusWrapper {
    display: flex;
    align-items: center;

    .nameStatus {
        flex-grow: 1;
    }

    .recurringSelect {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .recurringLabel {
        font-size: 14px;
        color: grey;
        text-align: right;
        margin-bottom: 4px;
        width: 225px;
    }
}

.avatarWrapper {
    position: relative;
}

.avatarStatus {
    position: absolute;
    bottom: -3px;
    right: -3px;
    z-index: 12;


    .statusIcon {
        font-size: 13px;
        background-color: white;
        border-radius: 50%;
        width: 13px;
        height: 13px;
    }
}

.avatarList {
    display: flex;
    align-items: center;
}

.responseTitleWrapper {
    display: flex;
    align-items: center;
}

.responseTitleLeft {
    flex-grow: 1;
}

.checkboxIncomplete {
    background: #ffa5002e;
    padding: 4px 10px;
    border-radius: 10px;
    color: #bb7900;
    // font-size: 14px;
}

.alertIncomplete {
    margin-top: 16px;
    border-radius: 10px;
}

.titleAlert {
    display: flex;

    .titleAlertLeft {
        flex-grow: 1;
    }
}

@media (max-width: 660px) {
    .vignettes {
        flex-wrap: wrap;
    }

    .vignetteElement {
        flex-basis: 50%;
    }

    .statusWrapper {
        flex-wrap: wrap;

        .nameStatus {
            flex-basis: 100%;
        }

        .recurringSelect {
            margin-top: 24px;
            flex-basis: 100%;
            align-items: flex-start;
        }

        .recurringLabel {
            text-align: left;
            width: 100%;
        }
    }
}

@media (max-width: 560px) {
    .questionHeader {
        flex-wrap: wrap;
    }

    .titleAlert {
        flex-wrap: wrap;

        .titleAlertLeft {
            flex-basis: 100%;
            margin-bottom: 12px;
        }
    }
}

@media (max-width: 500px) {
    .participationWrapper {
        flex-wrap: wrap;
    }

    .participationRight {
        margin-top: 12px;
    }
}

@media (max-width: 400px) {
    .vignetteElement {
        flex-basis: 100%;
    }
}