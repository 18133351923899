.title {
    margin-bottom: 12px;
}

.subtitle {
    margin-bottom: 24px;
}
.bread{
    margin-bottom: 34px;
}
.itemWrapper {
    display: flex;
    align-items: center;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #0000001a;
    // width: calc(100% - 48px);
}

.leftWrapper {
    flex-grow: 1;
}

.itemCTA {
    display: flex;
    align-items: center;
}

.itemParticipation {
    margin-right: 36px;
}

@media (max-width: 800px) {
    .itemWrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .leftWrapper,
    .itemParticipation {
        margin-bottom: 14px;
    }
}