.questionNumberInTitle {
    color: grey;
    font-weight: 400;
}

.themeTitle {
    margin-bottom: 12px;
    font-weight: bold;
}

.themeWrapper {
    margin-bottom: 12px;
}

.questionWrapper {
    margin-bottom: 34px;
}

.questionTitle {
    margin-bottom: 6px;
}

.noResponse {
    color: grey;
    font-style: italic;
}

.avatarWrapper {
    position: relative;
}
.completeTitle{
    margin-bottom: 12px;
    margin-top: 24px;
}
.avatarStatus {
    position: absolute;
    bottom: -6px;
    right: 0px;
    z-index: 12;


    .statusIcon {
        font-size: 12px;
        background-color: white;
        border-radius: 50%;
        width: 12px;
        height: 12px;
    }
}

.userElement {
    display: flex;
    align-content: center;
}

.usernameWrapper {
    text-align: left;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.date {
    color: grey;
    font-size: 14px;
}

.labelWrapper {
    display: flex;
    align-items: center;

    .labelLeft {
        flex-grow: 1;
    }
}